<template>
   <v-card flat text class="ma-0 pa-0" style="background-color:transparent;">
      <v-card-title class="ma-0 pa-0">
         <v-icon v-if='1==2' style='font-size:30px;'>mdi-calendar-range</v-icon>
         <v-select
            :items="allTypes"
            v-model="activeType"
            item-text ="label"
            item-value ="value"
            label="" dark color="#BDBDBD"
            style="width:100px;max-width:110px;background-color:#999999;display:inline-block;border-radius: 4px 0 0 4px;border-right: 0px solid black;"
            outlined dense
            hide-details
         ></v-select>
         <div class="datePickerContentDiv">
            <v-btn 
               v-if="activeType!='range'"
               icon class="ma-0 ml-1" color="primary"
               style="height:38px;width:38px;"
               @click="goPre">
               <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-menu
               ref="menu"
               v-model="menu"
               :close-on-content-click="false"
               :return-value.sync="dateStr"
               offset-y transition="scale-transition"
               max-width="290px"
               min-width="290px"
            >

               <template v-slot:activator="{ on }">
                  <v-btn v-if="activeType=='range'"
                     style="width:140px;height:38px;font-size:14px;"
                     color="#555555" text tile class="px-0 ma-0"
                     v-on='on'>
                     {{dateRangeText}}
                  </v-btn>
                  <v-btn v-else
                     style="width:110px;height:38px;font-size:16px;"
                     color="#555555" text tile class="px-0 ma-0"
                     v-on='on'>
                     {{renderDateStr}}
                  </v-btn>
               </template>

            <template v-if="activeType!='year'">
               <v-date-picker v-if="activeType!='range'"
                  v-model="dateStr"
                  no-title
                  :type="datePickerType"
                  scrollable
                  :first-day-of-week="1"
                  show-week
               >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="onCloseMenu">OK</v-btn>
               </v-date-picker>

               <v-date-picker v-if="activeType=='range'"
                  v-model="dateRanges"
                  no-title
                  scrollable
                  :first-day-of-week="1"
                  show-week
                  range
               >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="onCloseMenu">OK</v-btn>
               </v-date-picker>
            </template>
            </v-menu>

            <v-btn v-if="activeType!='range'"
               icon class="ma-0 mr-1" color="primary"
               style="height:38px;width:38px;"
               @click="goNext">
               <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
         </div>
         <br>
         <v-spacer/>
      </v-card-title>
      <v-card-text class="py-0 my-0 px-0 mx-0">
         <div v-if="1==2 && activeType == 'day'" style="margin-top:4px;display:block;">
            <v-select
                  v-model="fromHour"
                  :items="hoursList"
                  item-text="label"
                  item-value="value"
                  style="width:102px;max-width:102px;border-width: 1.5px;display:inline-block;margin-left:105px;"
                  dense outlined
                  single-line hide-details
               >
            </v-select>
            <v-select
               v-model="toHour"
               :items="hoursList"
               item-text="label"
               item-value="value"
               style="width:102px;max-width:102px;border-width: 1.5px;display:inline-block;margin-left:6px;"
               dense outlined
               single-line hide-details
            ></v-select>
         </div>

         <div v-if="activeType == 'range'" style="display:inline-block;">
            <div style="margin-top:4px;margin-left:48px;">
               <div style="min-width:60px;display:inline-block;">From</div>
               <span style="min-width:60px;display:inline-block;font-weight:600;">{{this.dateRanges[0]}}</span>
               <v-select
                     v-model="fromHour"
                     :items="hoursList"
                     item-text="label"
                     item-value="value"
                     style="width:102px;max-width:102px;border-width: 1.5px;display:inline-block;margin-left:30px;"
                     dense outlined
                     single-line hide-details
                  >
               </v-select>
            </div>
            <div style="margin-top:4px;margin-left:48px;">
               <div style="min-width:60px;display:inline-block;">To</div>
               <span style="min-width:60px;display:inline-block;font-weight:600;">{{this.dateRanges[this.dateRanges.length-1]}}</span>
               <v-select
                  v-model="toHour"
                  :items="hoursList"
                  item-text="label"
                  item-value="value"
                  style="width:102px;max-width:102px;border-width: 1.5px;display:inline-block;margin-left:30px;"
                  dense outlined
                  single-line hide-details
               ></v-select>
            </div>
         </div>
      </v-card-text>
   </v-card>
</template>

<script>
let moment = require('moment')
export default {
   props: ['value', 'defaultActiveType'],
   computed: {
      dateRangeText () {
        return this.dateRanges.join(' ~ ')
      },
      supportHour: function () {
         return this.activeType=='day'||this.activeType=='range'
      }
   },
   watch: {
      activeType: function () {
         if (this.activeType == 'day'){
            // this.dateStr = this.activeDate.toISOString().substr(0, 10)
            this.renderDateStr = moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
            this.datePickerType = 'date'
            this.notifyDateChangeToParent()
         }
         if (this.activeType == 'week'){
            this.datePickerType = 'date'
            this.activeDate = this.getFirstDateOfWeek(this.activeDate)
            this.dateStr = moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
            this.notifyDateChangeToParent()
         }
         if (this.activeType == 'month'){
            this.datePickerType = 'month'
            this.dateStr = moment(this.activeDate).format("YYYY-MM")
            this.renderDateStr = moment(this.activeDate).format("MM/YYYY")
            this.notifyDateChangeToParent()
         }
         if (this.activeType == 'year'){
            this.datePickerType = 'month'
            this.dateStr = moment(this.activeDate).format("YYYY")
            this.renderDateStr = moment(this.activeDate).format("YYYY")
            this.notifyDateChangeToParent()
         }
      },
      dateStr: function () {
         if (this.activeType == 'week') {
            this.activeDate = new Date(this.dateStr)
            // let expectStartDate = this.getFirstDateOfWeek(this.activeDate)
            // if(this.activeDate != expectStartDate) {
            //    this.activeDate = expectStartDate
            //    this.dateStr = this.activeDate.toISOString().substr(0, 10)
            // }
            
         }
         // this.activeDate = new Date(this.activeDate.toISOString().substr(0, 10))
         // this.notifyDateChangeToParent()
      },
      fromHour: function(){
         this.onDateRangeChange()
         this.notifyDateChangeToParent()
      },
      toHour: function () {
         this.onDateRangeChange()
         this.notifyDateChangeToParent()
      },
      dateRanges: function () {
         this.onDateRangeChange()
         this.notifyDateChangeToParent()
      }
   },
   data () {
      return {
         activeType: 'day',
         datePickerType: 'date',
         renderDateStr: moment(new Date()).add(0,'h').format("DD/MM/YYYY"),
         dateStr: moment(new Date()).format("YYYY-MM-DD"),
         dateRanges: [moment(new Date()).format("YYYY-MM-DD")],
         activeDate: new Date(),
         menu: false,
         hoursList: [],
         fromHour: 0,
         toHour: 1440,
         allTypes: [
            { label: 'Ngày', value: 'day'},
            { label: 'Tuần', value: 'week'},
            { label: 'Tháng', value: 'month'},
            // { label: 'Year', value: 'year'},
            // { label: 'Range', value: 'range'}
         ],
      }
   },
   methods: {
      onDateRangeChange () {
         if(this.dateRanges.length > 1){
            let d1 = new Date(this.dateRanges[0])
            let d2 = new Date(this.dateRanges[1])
            if(d1.getTime()>d2.getTime()){
               this.dateRanges = [moment(d2).format('YYYY-MM-DD'), moment(d1).format('YYYY-MM-DD')]
            }
         }
         if(this.dateRanges.length > 0){
            this.activeDate = new Date(this.dateRanges[0])
            this.dateStr = moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
         }
      },
      notifyDateChangeToParent () {
         let returnObj = {
            type: this.activeType,
            dateStr: this.dateStr,
            // dateStr: m.format("YYYY-MM-DD"),
            date: moment(new Date(this.dateStr)).toDate(),
            // date: moment(new Date(this.dateStr)).add(-7, 'hours').toDate(),
            dateStrFull: moment(new Date(this.dateStr)).format('DD/MM/YYYY HH:mm:ss')
         }
         let toDateM = moment()
         if (this.activeType == 'day'){
            toDateM = moment(new Date(this.dateStr)).add(1, 'days').add(-7, 'hours')
         }
         if (this.activeType == 'week'){
            toDateM = moment(new Date(this.dateStr)).add(7, 'days').add(-7, 'hours')
         }
         if (this.activeType == 'month'){
            toDateM = moment(new Date(this.dateStr)).add(1, 'months').add(-7, 'hours')
         }
         if (this.activeType == 'year'){
            toDateM = moment(new Date(this.dateStr)).add(1, 'years').add(-7, 'hours')
         }
          if (this.activeType == 'range'){
            if(this.dateRanges.length>1){
               toDateM = moment(new Date(this.dateRanges[1])).add(this.toHour, 'minutes').add(-7, 'hours')
            } else {
               toDateM = moment(new Date(this.dateRanges[0])).add(this.toHour, 'minutes').add(-7, 'hours')
            }
            
         }
         returnObj.dateStr = this.dateStr,
         returnObj.date = moment(new Date(this.dateStr)).add(-7, 'hours').add(this.fromHour, 'minutes').toDate(),
         returnObj.dateStrFull = new Date(returnObj.date).toISOString()
         returnObj.toDate = toDateM.toDate(),
         returnObj.toDateStr = toDateM.format("YYYY-MM-DD"),
         returnObj.toDateStrFull = new Date(returnObj.toDate).toISOString()
         // console.log(returnObj)
         this.$emit('dateChanged', returnObj)
      },
      onCloseMenu () {
         this.$refs.menu.save(this.dateStr)
         this.notifyDateChangeToParent()
      },
      getFirstDateOfWeek(date) {
         return moment(date).startOf('week').isoWeekday(2).toDate()
      },
      goNext () {
         if(this.activeType == 'day') {
            this.activeDate =  moment(this.activeDate).add(1, 'days');
            this.dateStr =  moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
         }
         if(this.activeType == 'week') {
            this.activeDate =  moment(this.activeDate).add(7, 'days');
            this.dateStr = moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
         }
         if(this.activeType == 'month') {
            this.activeDate =  moment(this.activeDate).add(1, 'months');
            this.dateStr = moment(this.activeDate).format("YYYY-MM")
            this.renderDateStr = moment(this.activeDate).format("MM/YYYY")
         }
         if(this.activeType == 'year') {
            this.activeDate =  moment(this.activeDate).add(1, 'year');
            this.dateStr = moment(this.activeDate).format("YYYY-MM")
            this.renderDateStr = moment(this.activeDate).format("YYYY")
         }
         this.notifyDateChangeToParent()
      },
      goPre () {
         if(this.activeType == 'day') {
            this.activeDate =  moment(this.activeDate).add(-1, 'days');
            this.dateStr = moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
         }
         if(this.activeType == 'week') {
            this.activeDate =  moment(this.activeDate).add(-7, 'days');
            this.dateStr = moment(this.activeDate).format("YYYY-MM-DD")
            this.renderDateStr = moment(this.activeDate).format("DD/MM/YYYY")
         }
         if(this.activeType == 'month') {
            this.activeDate =  moment(this.activeDate).add(-1, 'months');
            this.dateStr = moment(this.activeDate).format("YYYY-MM")
            this.renderDateStr = moment(this.activeDate).format("MM/YYYY")
         }
         if(this.activeType == 'year') {
            this.activeDate =  moment(this.activeDate).add(-1, 'year');
            this.dateStr = moment(this.activeDate).format("YYYY")
            this.renderDateStr = moment(this.activeDate).format("YYYY")
         }
         this.notifyDateChangeToParent()
      }
   },
   created () {
      for(let i=0; i<25; i++){
         this.hoursList.push({
            label:(i<10?'0':'')+ i + 'h', value: i*60
         })
      }
      if (this.defaultActiveType) {
         this.activeType = this.defaultActiveType
      }
      this.$nextTick(() => {
         this.notifyDateChangeToParent()
      })
   },
}
</script>

<style>
   .datePickerContentDiv {
      display:inline-block;
      height:40px;
      background-color:#F6F6F6;
      border-radius:  0 4px 4px 0;
      border-right: 1.5px solid #BDBDBD;
      border-bottom: 1.5px solid #BDBDBD;
      border-top: 1.5px solid #BDBDBD;
      border-left: 0px solid black;
   }
</style>